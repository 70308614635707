.main-authentication {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    .info {
        font-weight: bold;
    }

    .btn-2FA {
        margin-top: 15px;
    }

    .generate-code {
        margin-top: 15px;
        color: #007bff;
        cursor: pointer;
    }

    .verify-2FA {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 15px;

        .skip-2FA {
            color: #F90D0D;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.desc-disable-2FA {
    .title {
        font-weight: bold;
    }
}