.docs-nav-title {
  //.docs-logo, .docs-logo img {
  //  width: 100%;
  //}
}

.docs-logo {
  width: 100%;
  height: 50px;
  padding: 35px;

  &:focus {
    outline: none;
  }
}

.bp3-popover-wrapper {
  margin-left: 10px;
}

.docs-nav-divider {
  margin: 15px 0 0 15px;
}

.bp3-menu {
  background: transparent;
}

.nav-app-list {
  margin-right: 10px;

  &:focus {
    outline: none;
  }
}

.no-focus {
  outline: none;
}

@media screen and (max-width: 550px) {

  h1.bp3-heading {
    font-size: 25px;
  }
  .docs-logo {
    padding: 28px;
    margin-left: 10px;
  }
  .bp3-navbar.bp3-fixed-top {
    padding: 0;
    width: 440px;
  }
  .bp3-navbar-divider {
    margin: 0;
  }
  .bp3-navbar-group.bp3-align-right {
    float: left;
  }
  .bp3-navbar-group {
    width: 95px;
  }
  .bp3-fill {
    margin: 0;
  }
  .bp3-button .bp3-large .bp3-minimal .nav-app-list {
    margin-right: -10px;
    padding-left: -15px;
  }
  .bp3-button.bp3-large, .bp3-large .bp3-button {
    padding-left: 0;
    margin-right: -10px;
  }
  .bp3-active {
    padding-right: 30px;
  }
  .docs-app {
    width: 100%;
  }
  .bp3-button.bp3-large, .bp3-large .bp3-button {
    min-height: 35px;
    min-width: 35px;
    padding-right: 0;
  }
  .bp3-alert {
    width: 285px;
  }
  .bp3-menu {
    padding-left: 15px;
  }
}

@media screen and (max-width: 321px) {
  .bp3-fill {
    width: 400px;
  }
  .nav-user-info {
    width: 210px;
  }
  .bp3-menu {
    width: 0;
  }
}