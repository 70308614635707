main {
  width: 70%;
  margin: 0 auto;
  padding: 30px 0;
}

.docs-page {
  padding: 40px;

  .app-main {
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: row;

    .app-item {
      text-align: center;
      margin: 5px 10px;

      > a:focus {
        outline: none;
      }
    }

    .app-icon {
      width: 120px;
      height: 80px;
      border-radius: 16px;

      .deactivate {
        color: grey;
      }
    }

    .app-icon-map {
      background: url(../../assets/img/oxpay-logo.png) center center no-repeat;
      background-size: cover;
    }

  }
}

.app-icon {
  width: unset !important;
}

@media screen and (max-width: 550px) {
  h1.bp3-heading {
    font-size: 25px;
    margin: 20px 0 0 0;
  }
  .docs-page .app-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 300px;
    margin-top: 0;
  }
  .docs-page .app-main .app-item {
    margin: 30px 0 55px 0;
  }
}