.docs-page {
  position: relative;
  max-width: unset;
  padding-right: 40px;
}

.docs-app {
  //display: block;
  flex-direction: column;

  .bp3-fill {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
}

.docs-div {
  text-align: center;
}

.nav-user-info {
  &:focus {
    outline: none;
  }
}