@import "~@blueprintjs/core/src/common/variables";

:root {
  --purple: #6941C6;
  --white: #ffffff
}

.docs-app {
  max-width: unset;
}

.docs-app>* {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
*,

.docs-root,
.docs-nav,
.docs-content-wrapper,
.docs-example,
.docs-example-options,
.editor,
code {
  transition: background-color 300ms $pt-transition-ease;
}

.card-title {
  color: #106ba3;
}
.bp3-dark .card-title {
  color: #48aff0;
}

.oxpay-logo {
  background: url(./assets/img/oxpay-logo.png) center center no-repeat;
  background-size: contain;
}

.bp3-select-popover .bp3-menu {
  max-width: 400px;
  max-height: 300px;
  overflow: auto;
  padding: 0;
}

.bp3-form-helper-text {
  white-space: pre;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.purple-bg {
  background: var(--purple) !important;
  color: var(--white) !important
}

.link-style {
  color: #0077cc;
  cursor: pointer;
}