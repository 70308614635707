.login-page {
    // background: url(../../assets/img/login-bg.jpg) center center no-repeat;
    background-color: #F2F2F2;
    width: 100%;
    height: 100vh;

    .logo {
        display: block;
        margin: 30px auto 0 auto;
    }

    .login-section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        gap: 100px;
        margin-bottom: 10px;
        font-size: 12px;

        .contact-support {
            text-decoration: underline;
        }
    }
}

.verify-email-dialog {
    width: 400px;
    margin: 10px;
    .content {
        text-align: center;

        .description {
            line-height: 15px;
        }

        .form-input-code {
            margin: 20px 0;

            .bp3-form-helper-text {
                text-align: left;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .content {
            padding: 0 15px;
        }
    } 
}

.bp3-dark .login-page {
    background: #394b59;
    height: 100vh;
}

//Responsive mobile 
@media screen and (max-height: 900px) {
    .login-page {
        .logo {
            top: 0%;
        }
    }
}

@media screen and (max-height: 700px) {
    .login-page {
        .logo {
            display: none;
        }
    }
}